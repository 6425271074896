import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import Description from "../components/Description/Description";

export const postProductionImage = graphql`
  fragment postProductionImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "post_production/hero.jpg" }) {
      ...postProductionImage
    }
  }
`;

const PostProduction = ({ data }) => (
  <Layout pageUrl="post-production">
    <SEO title="Post Production" />
    <Hero
      home={false}
      pageTitle="Post Production"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="Post Production hero"
    />
    <Description
      heading="A fast and targeted post production"
      description="We can guarantee a quick and effective post production, calibrated according to the different types of shoots realized for the digital world. Our team can ensure a high speed delivery and the highest uniformity, both on images produced by Parallel Milano or on images taken by the brand itself."
    />
  </Layout>
);

export default PostProduction;
